import React, { useState, useEffect } from "react";
import axios from "axios";
import { MoonLoader } from "react-spinners";
//import DeepSpeech from 'deepspeech'

const FicheAppel = ({
  dataOffCanvas,
  bool,
  setBool,
  setBadge,
  verificationBadge,
  badge,
  updateHistorique,
  infoUsers,
  setDial,
  verificationDestination,
  formatDate,
  formatDuration,
  showNote,
  note,
  setNote,
  verificationFlux,
  setShowNote,
  imgRecepteur,
  verificationStatusAppel,
  verifyIfContact,
  allContact,
}) => {
  const [audioURL, setAudioURL] = useState("");
  //Gestion de transcription
  const [transcription, setTranscription] = useState("---");
  const [waitAudio, setWaitAudio] = useState(false);
  const [waitTranscription, setWaitTranscription] = useState(false);
  const [showTranscription, setShowTranscription] = useState(false);
  const recuperationAudio = async (filename) => {
    setWaitAudio(true);
    const config = {
      headers: {
        Authorization: `Bearer ${infoUsers.token}`,
      },
      responseType: "blob",
    };
    await axios
      .get(`${process.env.REACT_APP_URL}/audio/${filename}`, config)
      .then((res) => {
        if (res.data) {
          const audioBlob = res.data;
          const url = URL.createObjectURL(audioBlob);
          const file = new File([audioBlob], filename, {
            type: audioBlob.type,
          });
          // handleTranscription(file)
          setAudioURL(url);
        } else {
          setAudioURL(null);
        }
      })
      .catch((err) => {
        setAudioURL(null);
        console.log(err);
      })
      .finally((res) => {
        setWaitAudio(false);
      });
  };
  const recuperationTranscription = async () => {
    setWaitTranscription(true);
    const config = {
      headers: {
        Authorization: `Bearer ${infoUsers.token}`,
      },
    };
    await axios
      .get(
        `${process.env.REACT_APP_URL}/audio/transcribedAudio/${dataOffCanvas._id}`,
        config
      )
      .then((res) => {
        if (res.data?.data) {
          setTranscription(res.data?.data);
        } else {
          setTranscription("---");
        }
      })
      .catch((err) => {
        setTranscription("---");
        console.log(err);
      })
      .finally((res) => {
        setWaitTranscription(false);
      });
  };
  useEffect(() => {
    if (dataOffCanvas) {
      setShowTranscription(false);
      recuperationAudio(dataOffCanvas.recordingfile);
    }
  }, [dataOffCanvas]);
  return (
    <>
      <div class="offcanvas offcanvas-end" id="demo">
        <div class="offcanvas-header">
          <h1 class="offcanvas-title" style={{ color: "#334155" }}>
            Fiche d'appel{" "}
          </h1>
          <div>
            <span
              style={{ marginRight: "20px", cursor: "pointer" }}
              data-bs-toggle="dropdown"
            >
              {verificationBadge(badge)}
            </span>
            <ul class="dropdown-menu">
              <li
                onClick={(ev) => {
                  updateHistorique(
                    { _id: dataOffCanvas._id, badge: 1 },
                    infoUsers.token
                  )
                    .then((res) => {
                      setBadge(1);
                      setBool(!bool);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <a class="dropdown-item" href="#">
                  <i class="bi bi-star" style={{ color: "gray" }}></i>
                </a>
              </li>
              <li
                onClick={(ev) => {
                  updateHistorique(
                    { _id: dataOffCanvas._id, badge: 2 },
                    infoUsers.token
                  )
                    .then((res) => {
                      setBadge(2);
                      setBool(!bool);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <a class="dropdown-item" href="#">
                  <i class="bi bi-star-fill" style={{ color: "#FFC436" }}></i>
                </a>
              </li>
              <li
                onClick={(ev) => {
                  updateHistorique(
                    { _id: dataOffCanvas._id, badge: 3 },
                    infoUsers.token
                  )
                    .then((res) => {
                      setBadge(3);
                      setBool(!bool);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <a class="dropdown-item" href="#">
                  <i class="bi bi-star-fill" style={{ color: "#00e7eb" }}></i>
                </a>
              </li>
              <li
                onClick={(ev) => {
                  updateHistorique(
                    { _id: dataOffCanvas._id, badge: 4 },
                    infoUsers.token
                  )
                    .then((res) => {
                      setBadge(4);
                      setBool(!bool);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <a class="dropdown-item" href="#">
                  <i class="bi bi-star-fill" style={{ color: "orange" }}></i>
                </a>
              </li>
              <li
                onClick={(ev) => {
                  updateHistorique(
                    { _id: dataOffCanvas._id, badge: 5 },
                    infoUsers.token
                  )
                    .then((res) => {
                      setBadge(5);
                      setBool(!bool);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <a class="dropdown-item" href="#">
                  <i
                    class="bi bi-exclamation-circle-fill"
                    style={{ color: "#EB455F" }}
                  ></i>
                </a>
              </li>
              <li
                onClick={(ev) => {
                  updateHistorique(
                    { _id: dataOffCanvas._id, badge: 6 },
                    infoUsers.token
                  )
                    .then((res) => {
                      setBadge(6);
                      setBool(!bool);
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                <a class="dropdown-item" href="#">
                  <i
                    class="bi bi-check-circle-fill"
                    style={{ color: "#6cc070" }}
                  ></i>
                </a>
              </li>
            </ul>
            <i
              class="bi bi-telephone-fill"
              data-bs-dismiss="offcanvas"
              onClick={(ev) => {
                if (dataOffCanvas) {
                  setDial(verificationDestination(dataOffCanvas));
                }
              }}
              style={{
                color: "lightgray",
                marginRight: "40px",
                cursor: "pointer",
              }}
            ></i>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
            ></button>
          </div>
        </div>
        <div
          class="offcanvas-body"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {dataOffCanvas && (
            <>
              {verificationStatusAppel(dataOffCanvas)}
              <span style={{ marginTop: "5px" }}>
                {" "}
                {formatDate(dataOffCanvas.calldate)} .{" "}
                {formatDuration(dataOffCanvas.billsec)}{" "}
              </span>
              <div className="recepteur" style={{ marginTop: "5px" }}>
                <img className="imgRecepteur" src={imgRecepteur} alt="" />
                <div className="infoRecepteur">
                  <span className="nomRecepteur">
                    {" "}
                    {verificationDestination(dataOffCanvas)}
                  </span>
                  <span className="telRecepteur">
                    {verifyIfContact(
                      verificationDestination(dataOffCanvas),
                      allContact
                    )}
                  </span>
                </div>
              </div>
              {showNote ? (
                <>
                  {note && note.length > 0 ? (
                    <>
                      <span
                        onClick={(ev) => {
                          setShowNote(false);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#e6e4e4",
                          borderRadius: "8px",
                          marginTop: "5px",
                          padding: "5px 5px",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          class="bi bi-pencil-fill"
                          style={{ color: "gray" }}
                        ></i>{" "}
                        <span style={{ marginLeft: "5px" }}>
                          {dataOffCanvas.note}
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        onClick={(ev) => {
                          setShowNote(false);
                        }}
                        style={{
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#e6e4e4",
                          borderRadius: "8px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          class="bi bi-pencil-fill"
                          style={{ color: "gray" }}
                        ></i>
                      </span>
                    </>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  <textarea
                    className="form-control"
                    style={{ marginTop: "10px" }}
                    cols="20"
                    rows="6"
                    defaultValue={note}
                    onBlur={(ev) => {
                      updateHistorique(
                        { _id: dataOffCanvas._id, note: ev.target.value },
                        infoUsers.token
                      )
                        .then((res) => {
                          setNote(ev.target.value);
                          setBool(!bool);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  ></textarea>
                </>
              )}
              <span className="flux-appel"> Flux d'appel</span>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {verificationFlux(dataOffCanvas)}
              </div>
              <span className="flux-appel">Enregistrement</span>
              {waitAudio ? (
                <div
                  style={{
                    marginTop: "10px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <MoonLoader /* color="#61CE70" */ size={25} />
                </div>
              ) : (
                <>
                  <div
                    style={{
                      marginTop: "10px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {audioURL ? (
                      <>
                        <audio
                          controls
                          src={audioURL}
                          style={{ width: "100%" }}
                        />
                        {!showTranscription &&
                          (dataOffCanvas?.numero?.enableTranscrib ? (
                            <>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  className="btn btn-sm"
                                  style={{
                                    backgroundColor: "#6cc070 ",
                                    color: "white",
                                    marginRight: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "10px",
                                    textAlign: "center",
                                  }}
                                  onClick={(ev) => {
                                    setShowTranscription(true);
                                    recuperationTranscription();
                                  }}
                                >
                                  {" "}
                                  Voir transcription
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          ))}
                        {showTranscription && (
                          <>
                            <div style={{ width: "100%", marginTop: "20px" }}>
                              <span>Transcription de l'audio</span>
                              {waitTranscription ? (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <MoonLoader /* color="#61CE70" */ size={25} />
                                </div>
                              ) : (
                                <p style={{ width: "100%" }}>
                                  {transcription ? transcription : "---"}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      "---"
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FicheAppel;
