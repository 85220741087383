import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import userLogo from "../../assets/images/user.png";
import { getUser, updatePassword } from "../../helpers/user";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
const Parametrage = () => {
  const {addToast} = useToasts()
  const navigate = useNavigate()
  const infoUsers = JSON.parse(localStorage.getItem("infoUsers"));
  const [infoUser, setInfoUser] = useState(infoUsers);
  const [ancienMdp, setAncienMdp] = useState("");
  const [newMdp, setNewMdp] = useState("");
  const [confirmMdp, setConfirmMdp] = useState("");
  const [isValide, setIsValid] = useState(false);
  const [message, setMessage] = useState("");
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewMdp(newPassword);
    // Vérification du mot de passe
    const isValidPassword = /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(newPassword);
    if (isValidPassword) {
      setMessage("");
    } else {
      setMessage("Veuillez entrer un mot de passe plus complexe.");
    }
  };
  const handlePasswordConfirm = (e) => {
    const confirmPassword = e.target.value;
    setConfirmMdp(confirmPassword);
    if (newMdp.length > 0) {
      if (newMdp == e.target.value) {
        const isValidPassword = /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(newMdp);
        if (isValidPassword) {
          setMessage("");
          setIsValid(true);
        } else {
          setMessage("Veuillez entrer un mot de passe plus complexe.");
          setIsValid(false);
        }
      } else {
        setIsValid(false);
        setMessage("Le mot de passe ne correspond pas.");
      }
    } else {
      setMessage("Veuillez indiquer un nouveau mot de passe.");
      setIsValid(false);
    }
  };
  const changePassword = () => {
    if (ancienMdp == newMdp) {
      alert(`Le nouveau mot de passe ne doit pas être indentique à l'ancien`);
      setAncienMdp("");
      setNewMdp("");
      setConfirmMdp("");
    } else {
      updatePassword(ancienMdp, newMdp, confirmMdp, infoUser.token)
        .then((res) => {
          alert('Modification réussi, veuillez vous reconnecter')
          localStorage.clear()
          navigate('/')
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    getUser(infoUser.data.user._id, infoUser.token)
      .then((res) => {
        if (res.data.data.data) {
          let newUSer = infoUser;
          newUSer.data.user = res.data.data.data;
          setInfoUser(newUSer);
          localStorage.setItem("infoUsers", JSON.stringify(newUSer));
        }
      })
      .catch((err) => {
        console.log(err);
        if(err.response?.data?.message){
          addToast(err.response?.data?.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        }else{
          addToast(`Une erreur s'est produite.`, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      });
  }, []);

  return (
    <>
      <Header />
      <div
        className="cotainer-fluid"
        style={{ width: "100%", padding: "0px 0px" }}
      >
        <div className="container parametreCompte">
          <div className="containerPhotoUser">
            <span className="cadrePhoto">
              <img src={userLogo} className="photoUser" alt="" />
            </span>
            <span className="simpleTexte">
              {infoUser?.data?.user?.name && infoUser.data.user.name}{" "}
              {infoUser?.data?.user?.lastname && infoUser.data.user.lastname}
            </span>
          </div>
          <div className="container-fluid">
            <div className="cadreInfo">
              <div className="cadreBody">
                <span className="titre">EMAIL</span>
                <span className="simpleTexte">
                  {infoUser?.data?.user?.email && infoUser.data.user.email}
                </span>
              </div>
              <div className="cadreBody">
                <span className="titre">POSTE</span>
                <span className="simpleTexte">
                  {infoUser?.data?.user?.poste
                    ? infoUser.data.user.poste
                    : "---"}
                </span>
              </div>
            </div>
          </div>
          <div className="infoUser">
            <span className="simpleTexte"> Modifier le mot de passe</span>
            <div className="modifier">
              <span data-bs-toggle="offcanvas" data-bs-target="#pass">
                Modifier
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="offcanvas offcanvas-end" id="pass">
        <div class="offcanvas-header">
          <h1 class="offcanvas-title">Modification mot de passe</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div class="offcanvas-body">
          <form onSubmit={(ev) => {
            ev.preventDefault()
            changePassword()
          }}>
            <div class="mb-3 mt-3">
              <input
                type="password"
                class="form-control"
                placeholder="Ancien mot de passe"
                defaultValue={ancienMdp}
                onChange={(ev)=>setAncienMdp(ev.target.value)}
                required
              />
            </div>
            <div class="mb-3 mt-3">
              <input
                type="password"
                class="form-control"
                defaultValue={newMdp}
                placeholder="Nouveau mot de passe"
                onChange={(ev) => {
                  handlePasswordChange(ev);
                }}
                required
              />
            </div>
            <div class="mb-3 mt-3">
              <input
                type="password"
                class="form-control"
                placeholder="Confirmer mot de passe"
                defaultValue={confirmMdp}
                onChange={(ev) => {
                  handlePasswordConfirm(ev);
                }}
                required
              />
            </div>

            <div className="validationMdp">
              <span style={{color:'red',fontWeight:'500',fontSize:'12px',display:'flex',alignItems:'center'}}> {message.length>0 ? <><span>{message}</span> <i class="bi bi-patch-question-fill" data-bs-toggle="tooltip" data-bs-placement="top" style={{cursor:'pointer'}} title="Min 8 caractères,1 Maj,1 chif" ></i></> : ""} </span>
              <button
                class="btn btn-sm"
                style={{
                  backgroundColor: "#6cc070",
                  color: "white",
                  fontWeight: "500",
                }}
                type="submit"
                data-bs-dismiss="offcanvas"
                disabled={!isValide}
              >
                Valider
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Parametrage;
