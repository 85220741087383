import React, { useState, useEffect } from "react";
import imgRecepteur from "../../assets/images/user.png";
import { MoonLoader } from "react-spinners";
import { getAllHistorique, updateHistorique } from "../../helpers/historique";
import { useToasts } from "react-toast-notifications";
import { searchOptimizedData } from "../../helpers/recherche";
import FicheAppel from "./FicheAppel";
import "./historique.scss";
import moment from "moment";
const Historique = ({ bool, setDial, setBool, allExtension, allContact ,a}) => {
  const extraireNumero = (tableau) => {
    return tableau.map((el) => el.numero);
  };
  const [filtreAppel, setFiltreAppel] = useState([]);
  const dateAujourdhui = moment().utc().local().add(1, "days");
  const startDateFormate = dateAujourdhui.format("YYYY-MM-DDTHH:mm:ss");
  const dateOnWeekBefore = moment(dateAujourdhui).subtract(15, "days");
  const dateFormateeOneWeeBefore = dateOnWeekBefore.format(
    "YYYY-MM-DDTHH:mm:ss"
  );
  const [dateDebut, setDateDebut] = useState(dateFormateeOneWeeBefore);
  const [dateFin, setDateFin] = useState(startDateFormate);
  const { addToast } = useToasts();
  const [data, setData] = useState([]);
  const [showNote, setShowNote] = useState(true);
  const [dataOffCanvas, setDataOffCanvas] = useState();
  const currentDate = new Date();
  const oneWeekBefore = new Date(currentDate);
  oneWeekBefore.setDate(currentDate.getDate() - 10);
  const [filtreBadge, setFiltreBadge] = useState([]);
  const infoUsers = JSON.parse(localStorage.getItem("infoUsers"));
  const [listNumeUser, setListNumUser] = useState(
    allExtension ? extraireNumero(allExtension) : []
  );
  const [wait, setWait] = useState(false);
  const [badge, setBadge] = useState(1);
  const [note, setNote] = useState("");

  //verification si le numero est dans contacte
  const verifyIfContact = (numero, contacts) => {
    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].tel === numero) {
        return contacts[i].name;
      }
    }
    return "inconnu";
  };
  //verifier si el existe dans tableau
  const verificationSiExiste = (el, tableau) => {
    return tableau.includes(el);
  };

  //verisifcation status de l'appel (entrante, sortante,manqué)
  const verificationStatusAppel = (el) => {
    //appel sortant 2 , manque 3, entrante 1 , repondeur: 4
    if (el.statutAppel == 2) {
      return (
        <i
          class="bi bi-telephone-outbound-fill"
          style={{ color: "#00e7eb" }}
        ></i>
      );
    } else {
      if (el.statutAppel == 3) {
        return (
          <i class="bi bi-telephone-x-fill" style={{ color: "#EB455F" }}></i>
        );
      } else {
        if (el.statutAppel == 1) {
          return (
            <i
              class="bi bi-telephone-inbound-fill"
              style={{ color: "#6cc070" }}
            ></i>
          );
        } else {
          if (el.statutAppel == 4) {
            return <i class="bi bi-voicemail" style={{ color: "orange" }}></i>;
          } else {
            return (
              <i class="bi bi-telephone-x-fill" style={{ color: "orange" }}></i>
            );
          }
        }
      }
    }
  };
  const verificationClient = (el) => {
    if (el.pointeurNum == "src") {
      return el.src;
    } else {
      return el.dst;
    }
  };
  const verificationExterne = (el) => {
    if (el.pointeurNum == "src") {
      return el.dst;
    } else {
      return el.src;
    }
  };

  //formatage de la duration de l'appel
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  //verification flux de l'appel, qui à fait quoi
  const verificationFlux = (el) => {
    if (el.statutAppel == 1 && el.disposition === "ANSWERED") {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-down-left"
              style={{ color: "#6cc070" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel entrant
            </span>
          </span>
          <span className="flux">
            {" "}
            <img className="imgRecepteur" src={imgRecepteur} alt="" />
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Décroché
            </div>{" "}
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-telephone-fill" style={{ color: "#EB455F" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Fin de l'appel <br /> {formatDuration(el.billsec)}{" "}
            </div>{" "}
          </span>
        </>
      );
    } else if (el.statutAppel == 1 && el.disposition === "NO ANSWER") {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-down-left"
              style={{ color: "#6cc070" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel entrant
            </span>
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-telephone-fill" style={{ color: "#EB455F" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Fin de l'appel <br /> {formatDuration(el.billsec)}{" "}
            </div>{" "}
          </span>
        </>
      );
    } else if (el.statutAppel == 3) {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-down-left"
              style={{ color: "#6cc070" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel entrant
            </span>
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-telephone-fill" style={{ color: "#EB455F" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Fin de l'appel <br /> {formatDuration(el.billsec)}{" "}
            </div>{" "}
          </span>
        </>
      );
    } else if (el.statutAppel == 2 && el.disposition === "ANSWERED") {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-up-right"
              style={{ color: "#00e7eb" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel sortant
            </span>
          </span>
          <span className="flux">
            {" "}
            <img className="imgRecepteur" src={imgRecepteur} alt="" />
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Décroché par l'appelé
            </div>{" "}
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-telephone-fill" style={{ color: "#EB455F" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Fin de l'appel <br /> {formatDuration(el.billsec)}{" "}
            </div>{" "}
          </span>
        </>
      );
    } else if (el.statutAppel == 2 && el.disposition === "NO ANSWER") {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-up-right"
              style={{ color: "#00e7eb" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel sortant
            </span>
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-telephone-fill" style={{ color: "#EB455F" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Fin de l'appel <br /> {formatDuration(el.billsec)}{" "}
            </div>{" "}
          </span>
        </>
      );
    } else if (el.statutAppel == 2 && el.disposition === "BUSY") {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-up-right"
              style={{ color: "#00e7eb" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel sortant
            </span>
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-telephone-fill" style={{ color: "#EB455F" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Fin de l'appel <br /> {formatDuration(el.billsec)}{" "}
            </div>{" "}
          </span>
        </>
      );
    } else if (el.statutAppel == 4) {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-down-left"
              style={{ color: "#6cc070" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel entrant
            </span>
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-voicemail" style={{ color: "orange" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Répondeur
            </div>{" "}
          </span>
        </>
      );
    } else {
      {
        return <span className="flux-appel">Non disponible</span>;
      }
    }
  };
  //verificaiton de la badge 1.2.3.4.5.6
  const verificationBadge = (el) => {
    if (el == 1) {
      return <i class="bi bi-star" style={{ color: "gray" }}></i>;
    } else if (el == 2) {
      return <i class="bi bi-star-fill" style={{ color: "#FFC436" }}></i>;
    } else if (el == 3) {
      return <i class="bi bi-star-fill" style={{ color: "#00e7eb" }}></i>;
    } else if (el == 4) {
      return <i class="bi bi-star-fill" style={{ color: "orange" }}></i>;
    } else if (el == 5) {
      return (
        <i
          class="bi bi-exclamation-circle-fill"
          style={{ color: "#EB455F" }}
        ></i>
      );
    } else if (el == 6) {
      return (
        <i class="bi bi-check-circle-fill" style={{ color: "#6cc070" }}></i>
      );
    } else {
      return <i class="bi bi-star" style={{ color: "gray" }}></i>;
    }
  };
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);

    const jour = dateObj.getUTCDate();
    const mois = dateObj.toLocaleString("default", { month: "short" });
    const heure = ("0" + dateObj.getUTCHours()).slice(-2);
    const minutes = ("0" + dateObj.getUTCMinutes()).slice(-2);

    const dateFormatee = `${jour} ${mois} ${heure}:${minutes}`;
    return dateFormatee;
  };

  //raffraichissement et extraction de la lisde de numero sous format [num1, num2]
  useEffect(() => {
    setListNumUser(extraireNumero(allExtension));
  }, [allExtension]);

  //recuperation de la liste d'historique rattacher à l'utilisateur connecté
  useEffect(() => {
    setWait(true);
    getAllHistorique(
      infoUsers?.token,
      `fields=_id,clientId,numero,note,calldate,billsec,src,dst,disposition,badge,statutAppel,sourceNum,pointeurNum,recordingfile&sort=-calldate&calldate[gte]=${dateDebut}&calldate[lte]=${dateFin}${
        filtreAppel.length > 0 ? `&statutAppel=${filtreAppel}` : ""
      }${filtreBadge.length > 0 ? `&badge=${filtreBadge}` : ""}`
    )
      .then((res) => {
        console.log(res);
        if (res.data?.data) {
          setData(res.data.data);
        }
        setWait(false);
      })
      .catch((err) => {
        setWait(false);
        console.log(err);
        if (err.response?.data?.message) {
          addToast(err.response?.data?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          addToast(`Une erreur s'est produite.`, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .finally((res) => setWait(false));
  }, [dateDebut, dateFin, filtreAppel, filtreBadge, a,bool]);

  return (
    <>
      <div className="tabHistorique">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            padding: "0px 0px 0px 40px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="titreTabulator">Journal des appels</span>
          </div>
          <div
            className="champRecherche"
            style={{ display: "flex", alignItems: "center" }}
          >
            <button
              className="btn btn-sm"
              style={{
                backgroundColor: "#6cc070 ",
                color: "white",
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
              data-bs-toggle="offcanvas"
              data-bs-target="#filtre"
            >
              <i class="bi bi-filter" style={{ fontSize: "14px" }}></i>
              <span>Filtres</span>{" "}
            </button>
            <span
              style={{
                padding: "8px 12px 8px 12px",
                backgroundColor: "rgba(226, 232, 240, 0.6)",
                borderRadius: "8px",
                height: "40px",
                display: "flex",
              }}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#818CF8"
                  style={{ width: "18px", height: "18px" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </span>
              <input
                type="text"
                className="rechercheTabulator"
                placeholder="Recherche"
                onChange={(ev) => { 
                  const newData = searchOptimizedData(ev.target.value, data);
                  setData(newData);
                }}
              />
            </span>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: "90%",
            overflowY: "scroll",
            borderTop: "solid #E2E8F0 1px",
            marginTop: "16px",
          }}
        >
          <table class="table table-hover" style={{ border: "none" }}>
            <tbody>
              {wait ? (
                <>
                  <tr key={333}>
                    <td>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          padding: "30% 45%",
                        }}
                      >
                        <span>
                          <MoonLoader color="#61CE70" />
                        </span>
                      </div>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {data &&
                    data.map((el) => {
                      return (
                        <>
                          <tr>
                            {/* status de l'appel */}
                            <td
                              data-bs-toggle="offcanvas"
                              data-bs-target="#demo"
                              onClick={(ev) => {
                                setShowNote(true);
                                setDataOffCanvas(el);
                                setBadge(el.badge);
                                setNote(el.note);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {verificationStatusAppel(el)}
                            </td>

                            {/* info de l'autre client */}
                            <td
                              onClick={(ev) => {
                                setShowNote(true);
                                setDataOffCanvas(el);
                                setBadge(el.badge);
                                setNote(el.note);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="recepteur">
                                <img
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#demo"
                                  className="imgRecepteur"
                                  src={imgRecepteur}
                                  alt=""
                                />
                                <div className="infoRecepteur">
                                  <span
                                    className="nomRecepteur"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      ev.stopPropagation();
                                      setDial(verificationExterne(el));
                                    }}
                                  >
                                    {verificationExterne(el)}
                                  </span>
                                  <span className="telRecepteur">
                                    {verifyIfContact(
                                      verificationExterne(el),
                                      allContact
                                    )}
                                  </span>
                                </div>
                              </div>
                            </td>

                            {/* info du client */}
                            <td
                              data-bs-toggle="offcanvas"
                              data-bs-target="#demo"
                              onClick={(ev) => {
                                setShowNote(true);
                                setDataOffCanvas(el);
                                setBadge(el.badge);
                                setNote(el.note);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="recepteur">
                                <img
                                  className="imgRecepteur"
                                  src={imgRecepteur}
                                  alt=""
                                />
                                <div className="infoRecepteur">
                                  <span className="nomRecepteur">
                                    {infoUsers.data?.user?.prenom
                                      ? infoUsers.data.user.prenom
                                      : "Inconnu"}
                                  </span>
                                  <span className="telRecepteur">
                                    {verificationClient(el)}
                                  </span>
                                </div>
                              </div>
                            </td>

                            {/* date de l'apppel et durée */}
                            <td
                              data-bs-toggle="offcanvas"
                              data-bs-target="#demo"
                              onClick={(ev) => {
                                setShowNote(true);
                                setDataOffCanvas(el);
                                setBadge(el.badge);
                                setNote(el.note);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="infoAppel">
                                <span className="heureAppel">
                                  {formatDate(el.calldate)}
                                </span>
                                <span className="durationAppel">
                                  <i className="fa fa-phone"></i>
                                  <span style={{ marginLeft: "5px" }}>
                                    {formatDuration(el.billsec)}
                                  </span>
                                </span>
                              </div>
                            </td>

                            {/* verification badge */}
                            <td style={{ cursor: "pointer" }}>
                              <div data-bs-toggle="dropdown">
                                {verificationBadge(el.badge)}
                              </div>
                              <ul class="dropdown-menu">
                                <li
                                  onClick={(ev) => {
                                    updateHistorique(
                                      { _id: el._id, badge: 1 },
                                      infoUsers.token
                                    )
                                      .then((res) => {
                                        setBool(!bool);
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  }}
                                >
                                  <a class="dropdown-item" href="#">
                                    <i
                                      class="bi bi-star"
                                      style={{ color: "gray" }}
                                    ></i>
                                  </a>
                                </li>
                                <li
                                  onClick={(ev) => {
                                    updateHistorique(
                                      { _id: el._id, badge: 2 },
                                      infoUsers.token
                                    )
                                      .then((res) => {
                                        setBool(!bool);
                                      })
                                      .catch((err) => {
                                        if (err.response?.data?.message) {
                                          addToast(
                                            err.response?.data?.message,
                                            {
                                              appearance: "error",
                                              autoDismiss: true,
                                            }
                                          );
                                        } else {
                                          addToast(
                                            `Une erreur s'est produite.`,
                                            {
                                              appearance: "error",
                                              autoDismiss: true,
                                            }
                                          );
                                        }
                                      });
                                  }}
                                >
                                  <a class="dropdown-item" href="#">
                                    <i
                                      class="bi bi-star-fill"
                                      style={{ color: "#FFC436" }}
                                    ></i>
                                  </a>
                                </li>
                                <li
                                  onClick={(ev) => {
                                    updateHistorique(
                                      { _id: el._id, badge: 3 },
                                      infoUsers.token
                                    )
                                      .then((res) => {
                                        setBool(!bool);
                                      })
                                      .catch((err) => {
                                        if (err.response?.data?.message) {
                                          addToast(
                                            err.response?.data?.message,
                                            {
                                              appearance: "error",
                                              autoDismiss: true,
                                            }
                                          );
                                        } else {
                                          addToast(
                                            `Une erreur s'est produite.`,
                                            {
                                              appearance: "error",
                                              autoDismiss: true,
                                            }
                                          );
                                        }
                                      });
                                  }}
                                >
                                  <a class="dropdown-item" href="#">
                                    <i
                                      class="bi bi-star-fill"
                                      style={{ color: "#00e7eb" }}
                                    ></i>
                                  </a>
                                </li>
                                <li
                                  onClick={(ev) => {
                                    updateHistorique(
                                      { _id: el._id, badge: 4 },
                                      infoUsers.token
                                    )
                                      .then((res) => {
                                        setBool(!bool);
                                      })
                                      .catch((err) => {
                                        if (err.response?.data?.message) {
                                          addToast(
                                            err.response?.data?.message,
                                            {
                                              appearance: "error",
                                              autoDismiss: true,
                                            }
                                          );
                                        } else {
                                          addToast(
                                            `Une erreur s'est produite.`,
                                            {
                                              appearance: "error",
                                              autoDismiss: true,
                                            }
                                          );
                                        }
                                      });
                                  }}
                                >
                                  <a class="dropdown-item" href="#">
                                    <i
                                      class="bi bi-star-fill"
                                      style={{ color: "orange" }}
                                    ></i>
                                  </a>
                                </li>
                                <li
                                  onClick={(ev) => {
                                    updateHistorique(
                                      { _id: el._id, badge: 5 },
                                      infoUsers.token
                                    )
                                      .then((res) => {
                                        setBool(!bool);
                                      })
                                      .catch((err) => {
                                        if (err.response?.data?.message) {
                                          addToast(
                                            err.response?.data?.message,
                                            {
                                              appearance: "error",
                                              autoDismiss: true,
                                            }
                                          );
                                        } else {
                                          addToast(
                                            `Une erreur s'est produite.`,
                                            {
                                              appearance: "error",
                                              autoDismiss: true,
                                            }
                                          );
                                        }
                                      });
                                  }}
                                >
                                  <a class="dropdown-item" href="#">
                                    <i
                                      class="bi bi-exclamation-circle-fill"
                                      style={{ color: "#EB455F" }}
                                    ></i>
                                  </a>
                                </li>
                                <li
                                  onClick={(ev) => {
                                    updateHistorique(
                                      { _id: el._id, badge: 6 },
                                      infoUsers.token
                                    )
                                      .then((res) => {
                                        setBool(!bool);
                                      })
                                      .catch((err) => {
                                        if (err.response?.data?.message) {
                                          addToast(
                                            err.response?.data?.message,
                                            {
                                              appearance: "error",
                                              autoDismiss: true,
                                            }
                                          );
                                        } else {
                                          addToast(
                                            `Une erreur s'est produite.`,
                                            {
                                              appearance: "error",
                                              autoDismiss: true,
                                            }
                                          );
                                        }
                                      });
                                  }}
                                >
                                  <a class="dropdown-item" href="#">
                                    <i
                                      class="bi bi-check-circle-fill"
                                      style={{ color: "#6cc070" }}
                                    ></i>
                                  </a>
                                </li>
                              </ul>
                            </td>

                            {/* Rappeler */}
                            <td style={{ cursor: "pointer" }}>
                              <div
                                onClick={(ev) => {
                                  setDial(verificationExterne(el));
                                }}
                              >
                                <i
                                  class="bi bi-telephone-fill"
                                  style={{ color: "lightgray" }}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div class="offcanvas offcanvas-end" id="filtre">
        <div class="offcanvas-header">
          <h1 class="offcanvas-title">Filtre</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div className="simpleTexte">Type d'appel</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <button
              className="btn btn-sm"
              style={
                filtreAppel.includes(1)
                  ? { backgroundColor: "#6cc070 ", color: "white" }
                  : { border: "solid #6cc070 1px ", color: "#6cc070 " }
              }
              onClick={(ev) => {
                if (filtreAppel.includes(1)) {
                  setFiltreAppel(filtreAppel.filter((el) => el != 1));
                } else {
                  setFiltreAppel([...filtreAppel, 1]);
                }
              }}
            >
              {" "}
              entrante
            </button>
            <button
              className="btn btn-sm"
              style={
                filtreAppel.includes(2)
                  ? { backgroundColor: "#00e7eb ", color: "white" }
                  : { border: "solid #00e7eb 1px ", color: "#00e7eb " }
              }
              onClick={(ev) => {
                if (filtreAppel.includes(2)) {
                  setFiltreAppel(filtreAppel.filter((el) => el != 2));
                } else {
                  setFiltreAppel([...filtreAppel, 2]);
                }
              }}
            >
              {" "}
              sortante
            </button>
            <button
              className="btn btn-sm"
              style={
                filtreAppel.includes(3)
                  ? { backgroundColor: "#EB455F ", color: "white" }
                  : { border: "solid #EB455F 1px ", color: "#EB455F " }
              }
              onClick={(ev) => {
                if (filtreAppel.includes(3)) {
                  setFiltreAppel(filtreAppel.filter((el) => el != 3));
                } else {
                  setFiltreAppel([...filtreAppel, 3]);
                }
              }}
            >
              {" "}
              Manqué
            </button>
            <button
              className="btn btn-sm"
              style={
                filtreAppel.includes(4)
                  ? { backgroundColor: "orange ", color: "white" }
                  : { border: "solid orange 1px ", color: "orange " }
              }
              onClick={(ev) => {
                if (filtreAppel.includes(4)) {
                  setFiltreAppel(filtreAppel.filter((el) => el != 4));
                } else {
                  setFiltreAppel([...filtreAppel, 4]);
                }
              }}
            >
              {" "}
              Répondeur
            </button>
          </div>
          <div className="simpleTexte" style={{ marginTop: "20px" }}>
            date
          </div>
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <input
              type="date"
              className="form-control m-2"
              defaultValue={dateDebut.split("T")[0]}
              onChange={(ev) => {
                const newValue = moment(ev.target.value).format(
                  "YYYY-MM-DDTHH:mm:ss"
                );
                setDateDebut(newValue);
                //setStartDate(ev.target.value);
              }}
            />
            <input
              type="date"
              className="form-control m-2"
              defaultValue={dateFin.split("T")[0]}
              onChange={(ev) => {
                const newValue = moment(ev.target.value).format(
                  "YYYY-MM-DDTHH:mm:ss"
                );
                setDateFin(newValue);
                //setEndDate(ev.target.value);
              }}
            />
          </div>
          <div className="simpleTexte" style={{ marginTop: "20px" }}>
            Badge
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              className="btn btn-sm"
              style={{
                border: "solid lightgray 1px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
              onClick={(ev) => {
                if (filtreBadge.includes(1)) {
                  setFiltreBadge(filtreBadge.filter((el) => el != 1));
                } else {
                  setFiltreBadge([...filtreBadge, 1]);
                }
              }}
            >
              <i class="bi bi-star" style={{ color: "gray" }}></i>
              {filtreBadge.includes(1) && (
                <>
                  <i
                    class="bi bi-bookmark-check-fill"
                    style={{ color: "#6cc070 " }}
                  ></i>
                </>
              )}
            </button>
            <button
              className="btn btn-sm"
              style={{
                border: "solid #FFC436 1px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
              onClick={(ev) => {
                if (filtreBadge.includes(2)) {
                  setFiltreBadge(filtreBadge.filter((el) => el != 2));
                } else {
                  setFiltreBadge([...filtreBadge, 2]);
                }
              }}
            >
              <i class="bi bi-star-fill" style={{ color: "#FFC436" }}></i>
              {filtreBadge.includes(2) && (
                <>
                  <i
                    class="bi bi-bookmark-check-fill"
                    style={{ color: "#6cc070 " }}
                  ></i>
                </>
              )}
            </button>
            <button
              className="btn btn-sm"
              style={{
                border: "solid #00e7eb 1px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
              onClick={(ev) => {
                if (filtreBadge.includes(3)) {
                  setFiltreBadge(filtreBadge.filter((el) => el != 3));
                } else {
                  setFiltreBadge([...filtreBadge, 3]);
                }
              }}
            >
              <i class="bi bi-star-fill" style={{ color: "#00e7eb" }}></i>
              {filtreBadge.includes(3) && (
                <>
                  <i
                    class="bi bi-bookmark-check-fill"
                    style={{ color: "#6cc070 " }}
                  ></i>
                </>
              )}
            </button>
            <button
              className="btn btn-sm"
              style={{
                border: "solid orange 1px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
              onClick={(ev) => {
                if (filtreBadge.includes(4)) {
                  setFiltreBadge(filtreBadge.filter((el) => el != 4));
                } else {
                  setFiltreBadge([...filtreBadge, 4]);
                }
              }}
            >
              <i class="bi bi-star-fill" style={{ color: "orange" }}></i>
              {filtreBadge.includes(4) && (
                <>
                  <i
                    class="bi bi-bookmark-check-fill"
                    style={{ color: "#6cc070 " }}
                  ></i>
                </>
              )}
            </button>
            <button
              className="btn btn-sm"
              style={{
                border: "solid #EB455F 1px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
              onClick={(ev) => {
                if (filtreBadge.includes(5)) {
                  setFiltreBadge(filtreBadge.filter((el) => el != 5));
                } else {
                  setFiltreBadge([...filtreBadge, 5]);
                }
              }}
            >
              <i
                class="bi bi-exclamation-circle-fill"
                style={{ color: "#EB455F" }}
              ></i>
              {filtreBadge.includes(5) && (
                <>
                  <i
                    class="bi bi-bookmark-check-fill"
                    style={{ color: "#6cc070 " }}
                  ></i>
                </>
              )}
            </button>
            <button
              className="btn btn-sm"
              style={{
                border: "solid #6cc070 1px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
              onClick={(ev) => {
                if (filtreBadge.includes(6)) {
                  setFiltreBadge(filtreBadge.filter((el) => el != 6));
                } else {
                  setFiltreBadge([...filtreBadge, 6]);
                }
              }}
            >
              <i
                class="bi bi-check-circle-fill"
                style={{ color: "#6cc070" }}
              ></i>
              {filtreBadge.includes(6) && (
                <>
                  <i
                    class="bi bi-bookmark-check-fill"
                    style={{ color: "#6cc070 " }}
                  ></i>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <FicheAppel
        dataOffCanvas={dataOffCanvas}
        bool={bool}
        setBool={setBool}
        setBadge={setBadge}
        verificationBadge={verificationBadge}
        badge={badge}
        updateHistorique={updateHistorique}
        infoUsers={infoUsers}
        setDial={setDial}
        verificationDestination={verificationExterne}
        formatDate={formatDate}
        formatDuration={formatDuration}
        showNote={showNote}
        note={note}
        setNote={setNote}
        verificationFlux={verificationFlux}
        setShowNote={setShowNote}
        imgRecepteur={imgRecepteur}
        verificationStatusAppel={verificationStatusAppel}
        verifyIfContact={verifyIfContact}
        allContact={allContact}
      />
    </>
  );
};

export default Historique;
