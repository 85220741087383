import axios from "axios";

const getAllHistorique = async (token,query) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/historyOneUser/all?${query}`,
      config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};
const syncHistorique = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/syncHistory`,
      config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};
const updateHistorique = async (datas,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_URL}/historyOneUser/${datas._id}`,{
        ...datas
      },config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};
const sendAudio = async (dataAudio,token) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_URL}/audio/addAudio`,
        dataAudio,config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};
export {getAllHistorique,updateHistorique,syncHistorique,sendAudio}