import axios from "axios";

const getAllContact = async (token,idUser) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/contact?archive=false&clientId=${idUser}&sort=name`,
      config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};

const addContact = async (datas,token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/contact/create`,datas,config
      );
      return data;
    } catch (err) {
      return {
        erreur: err,
      };
    }
};

const updateContact = async (datas,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_URL}/contact/${datas._id}`,datas,config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};

export {getAllContact,updateContact,addContact}