import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/Logo_Noir-1.png";
import userImage from "../assets/images/user.png";
const Header = ({ active, uA }) => {
  const navigate = useNavigate();
  const infoUsers = JSON.parse(localStorage.getItem("infoUsers"));
  return (
    <>
      <header style={{ marginBottom: "20px" }}>
        <nav
          class="navbar navbar-expand-lg bg-body-tertiary"
          style={{ padding: "0" }}
        >
          <div class="container-fluid" style={{ backgroundColor: "white" }}>
            <a
              class="navbar-brand"
              onClick={(ev) => {
                navigate("/Appel");
              }}
              style={{
                paddingTop: "10px",
                color: "white",
                fontWeight: "600",
                border: "none",
              }}
            >
              <img src={logo} alt="" className="logoNav" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li
                  class="nav-item"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={(ev) => {
                    //navigate("/Parametre");
                  }}
                ></li>
              </ul>
              <form
                class="d-flex headerBottom"
                style={{ justifyContent: "center", alignItems: "center" }}
                onSubmit={(e) => {
                  e.preventDefault();
                  //localStorage.clear();
                  //navigate("/");
                }}
              >
                <button
                  class="btn btn-sm"
                  style={{ backgroundColor: "#6cc070", color: "white" }}
                >
                  <a
                    href={process.env.REACT_APP_DASHBOARD}
                    target="_blank"
                    style={{ color: "white" }}
                  >
                    Tableau de bord
                  </a>
                </button>
                <div style={{ marginLeft: "20px" }}>
                  <img
                    src={userImage}
                    alt=""
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      border: "solid gray 1px",
                    }}
                  />
                  <span
                    style={{
                      color: "#334155",
                      marginLeft: "10px",
                      fontWeight: "500",
                    }}
                  >
                    {infoUsers?.data?.user?.nom ? infoUsers.data.user.nom : ""}{" "}
                    {infoUsers?.data?.user?.prenom
                      ? infoUsers?.data.user.prenom
                      : ""}
                  </span>
                </div>
                <i
                  class="bi bi-box-arrow-right"
                  style={{
                    color: "#6cc070",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#myModal01"
                ></i>

                <div class="modal" id="myModal01" style={{ zIndex: "9999" }}>
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title">Déconnexion</h4>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                        ></button>
                      </div>

                      <div class="modal-body">
                        Voulez-vous vraiment vous déconnecter ?
                      </div>

                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-danger"
                          data-bs-dismiss="modal"
                        >
                          Annuler
                        </button>
                        <button
                          type="button"
                          class="btn"
                          style={{ color: "white", backgroundColor: "#6cc070" }}
                          data-bs-dismiss="modal"
                          onClick={(ev) => {
                            if (uA) {
                              console.log(uA);
                              uA.stop();
                            }
                            localStorage.clear();
                            navigate("/");
                          }}
                        >
                          Confirmer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
