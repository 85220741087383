import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import logo from "../assets/images/Logo_Noir-1.png";
import "./login.scss";
import LoaderFixed from "../components/LoaderFixed";
const Login = () => {
  const { addToast } = useToasts();
  const [wait, setWait] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState();
  const navigate = useNavigate();
  const infoUsers = JSON.parse(localStorage.getItem("infoUsers"));
  console.log(infoUsers);

  const handleLogin = async () => {
    setWait(true);
    await axios
      .post(`${process.env.REACT_APP_URL}/utilisateurs/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.data) {
          localStorage.setItem("isLogin", true);
          localStorage.setItem("infoUsers", JSON.stringify(res.data));
          navigate("/Appel");
        } else {
          addToast(`Une erreur s'est produite.`, {
            appearance: "error",
            autoDismiss: true,
          });
          localStorage.setItem("isLogin", false);
        }
      })
      .catch((err) => {
        localStorage.setItem("isLogin", false);
        console.log(err);
        if (err.response?.data?.message) {
          addToast(err.response?.data?.message, {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          addToast(`Une erreur s'est produite.`, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
    setWait(false);
  };
  useEffect(() => {
    if (infoUsers?.token) {
      navigate("/Appel");
    }
  }, []);
  return (
    <>
      <div className="bodyVt">
        <div class="container containerVt" id="container">
          <div class="form-container sign-up-container">
            <form action="#" className="forms">
              <img src={logo} className="logo" alt="" />
              <input type="text" placeholder="Nom" className="inputs" />
              <input type="email" placeholder="Email" className="inputs" />
              {/*  <input
                type="password"
                placeholder="Password"
                className="inputs"
              /> */}
              <button
                onClick={(ev) => {
                  ev.preventDefault();
                }}
                className="buttonVt"
              >
                Demander un démo
              </button>
            </form>
          </div>
          <div class="form-container sign-in-container">
            <form
              onSubmit={(ev) => {
                ev.preventDefault();
                handleLogin();
              }}
              className="forms"
            >
              <img src={logo} className="logo" alt="" />
              <input
                type="email"
                className="inputs"
                placeholder="Email"
                id="mailInputLogin"
                onChange={(ev) => {
                  setEmail(ev.target.value);
                }}
                required
              />
              <input
                type="password"
                className="inputs"
                placeholder="Mot de passe"
                id="passInputLogin"
                onChange={(ev) => {
                  setPassword(ev.target.value);
                }}
                required
              />
              <a className="aVt" href="#">
                Mot de passe oublié?
              </a>
              <button type="submit" className="buttonVt" id="loginButton">
                Connexion
              </button>
            </form>
          </div>
          <div class="overlay-container">
            <div class="overlay">
              <div class="overlay-panel overlay-left">
                <h1 className="h1Vt">Vous avez déjà un compte !?</h1>
                <p className="pVt">
                  Pour vous connecter, cliquez sur ce bouton et indiquez vos
                  identifiants.
                </p>
                <button class="buttonVt ghost" id="signIn">
                  Se connecter
                </button>
              </div>
              <div class="overlay-panel overlay-right">
                <h1 className="h1Vt">APPEL MANUEL</h1>
                <p className="pVt">
                  Entrez votre identifiant et votre mot de passe pour vous
                  connecter.
                </p>
                <button class=" buttonVt ghost" id="signUp">
                  Tableau de bord
                </button>
              </div>
            </div>
          </div>
        </div>
        {wait && (
          <>
            <LoaderFixed />
          </>
        )}
      </div>
    </>
  );
};

export default Login;
