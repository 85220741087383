import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import Home from './pages/Home'
import PageNotFound from './components/PageNotFound'
import AppelManuel from './pages/appelManuel/AppelManuel'
import Parametrage from './pages/parametrage/Parametrage'
import Login from './pages/Login'
import SessionExpired from './components/SessionExpired'
import { store } from './redux'
import {ToastProvider} from 'react-toast-notifications'
function App() {
  return (
    <ToastProvider>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path='/Home' element={<Home />}/>
          <Route path='/' element={<Login />}/>
          <Route path='/Appel' element={<AppelManuel />}/>
          <Route path='/Parametre' element={<Parametrage />}/>
          <Route path='/Expired_session' element={<SessionExpired />}/>
          <Route path='*' element={<PageNotFound />}/>
        </Routes>
      </BrowserRouter>
    </Provider>
    </ToastProvider>
  )
}

export default App
