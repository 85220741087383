import React from "react";

const Appel = ({
  setDial,
  dial,
  etablishing,
  invitation,
  setIsCallIn,
  setInvitation,
  call,
  setItemContact,
  allExtension,
  extensionToUse,
  setExtensionToUse,
  numeroActuel,
  updateUser,
  bool,
  setBool,
  listCampagne,
  filtreNum,
  setFiltreNum,
}) => {
  const infoUsers = JSON.parse(localStorage.getItem("infoUsers"));
  const verificationFiltreNum = (filtreNum, element) => {
    if (filtreNum == "tous") {
      return true;
    } else {
      if (element == filtreNum) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <>
      <div
        id="caller-digits"
        class="is-unselectable box has-text-centered"
        style={{
          display: "block",
          maxHeight: "550px",
          backgroundColor: "#334155",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="btn dropdown dropend"
            data-bs-toggle="dropdown"
            style={{
              width: "70%",
              backgroundColor: "white",
              marginBottom: "10px",
              padding: "0px 5px",
            }}
            onClick={(ev) => {
              ev.preventDefault();
            }}
          >
            <div style={{ width: "100%" }}>
              <i class="bi bi-phone-fill" style={{ color: "#6cc070 " }}></i>
              <span style={{ fontSize: "16px", fontWeight: "bold" }} key={111}>
                {numeroActuel}
              </span>
            </div>

            <ul className="dropdown-menu">
              {allExtension && allExtension.length > 0 ? (
                <>
                  {allExtension.map((el) => {
                    return (
                      <>
                        <li
                          disabled={true}
                          onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                          }}
                          style={
                            verificationFiltreNum(filtreNum, el._id)
                              ? {}
                              : { display: "none"}
                          }
                        >
                          <a
                            className="dropdown-item"
                            style={{ backgroundColor: "lightgray" }}
                          >
                            {el.nom}
                          </a>
                        </li>
                        {el.numeros && el.numeros.length > 0 ? (
                          el.numeros.map((num) => {
                            return (
                              <>
                                <li
                                  style={
                                    verificationFiltreNum(filtreNum, el._id)
                                      ? {  }
                                      : {display: "none"}
                                  }
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    //setExtensionToUse(el);
                                    updateUser(
                                      {
                                        numeroActuel: num.numero,
                                      },
                                      infoUsers.data.user._id,
                                      infoUsers.token
                                    )
                                      .then((res) => {
                                        console.log(res);
                                        setBool(!bool);
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                        setBool(!bool);
                                      });
                                  }}
                                >
                                  <a class="dropdown-item" href="#">
                                    {num.numero ? num.numero : "Indéfini"}
                                  </a>
                                </li>
                              </>
                            );
                          })
                        ) : (
                          <li>
                            <a className="dropdown-item">
                              Aucun numéro associé
                            </a>
                          </li>
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                <>Vous n'avez aucun numéro</>
              )}
            </ul>
          
          </div>
          <div style={{ marginBottom: "10px", marginLeft: "5px" }}>
            <button
              className="btn btn-sm"
              style={{ backgroundColor: "#6cc070", color: "white" }}
              data-bs-toggle="dropdown"
            >
              {" "}
              <i class="bi bi-filter" style={{ fontSize: "16px" }}></i>{" "}
            </button>
            <ul class="dropdown-menu">
              <li onClick={(ev)=>{
                ev.preventDefault()
                setFiltreNum('tous')
              }}>
                <a
                  class="dropdown-item"
                  href="#"
                  style={
                    filtreNum == "tous" ? { backgroundColor: "lightgray" } : {}
                  }
                >
                  Tous
                </a>
              </li>
              <li
                onClick={(ev) => {
                  ev.preventDefault();
                  setFiltreNum(null);
                }}
              >
                <a
                  class="dropdown-item"
                  href="#"
                  style={
                    filtreNum == null ? { backgroundColor: "lightgray" } : {}
                  }
                >
                  Numéros personnel
                </a>
              </li>
              {listCampagne &&
                listCampagne.length > 0 &&
                listCampagne.map((el) => {
                  return (
                    <>
                      <li
                        onClick={(ev) => {
                          ev.preventDefault();
                          setFiltreNum(el._id);
                        }}
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          style={
                            filtreNum == el._id
                              ? { backgroundColor: "lightgray" }
                              : {}
                          }
                        >
                          {el.nom ? el.nom : "Non défini"}
                        </a>
                      </li>
                    </>
                  );
                })}
            </ul>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <input
            type="phone"
            className="form-control"
            value={dial}
            onChange={(ev) => {
              setDial(ev.target.value);
            }}
            style={{
              width: "70%",
              backgroundColor: "white",
              height: "50px",
              textAlign: "center",
              marginBottom: "10px",
              paddingRight: "20px",
            }}
          />
          <i
            class="bi bi-backspace-fill"
            onClick={(ev) => {
              setDial(dial.slice(0, -1));
            }}
            style={{
              position: "absolute",
              right: "17%",
              color: "lightgray",
              marginBottom: "15px",
              fontSize: "25px",
              cursor: "pointer",
              backgroundColor: "white",
            }}
          ></i>
        </div>

        <div class="call-row">
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDial(`${dial}` + "1");
            }}
          >
            1
          </a>
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDial(`${dial}` + "2");
            }}
          >
            2
          </a>
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDial(`${dial}` + "3");
            }}
          >
            3
          </a>
        </div>

        <div class="call-row">
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDial(`${dial}` + "4");
            }}
          >
            4
          </a>
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDial(`${dial}` + "5");
            }}
          >
            5
          </a>
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDial(`${dial}` + "6");
            }}
          >
            6
          </a>
        </div>

        <div class="call-row">
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDial(`${dial}` + "7");
            }}
          >
            7
          </a>
          <span
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              console.log(dial);
              setDial(`${dial}` + "8");
            }}
          >
            8
          </span>
          <a
            class="button is-large"
            onClick={(ev) => {
              setDial(`${dial}` + "9");
            }}
          >
            9
          </a>
        </div>

        <div>
          <a
            class="button is-large"
            onClick={(ev) => {
              setDial(`${dial}` + "*");
            }}
          >
            *
          </a>
          <a
            class="button is-large"
            onClick={(ev) => {
              setDial(`${dial}` + "0");
            }}
          >
            0
          </a>
          <a
            class="button is-large"
            onClick={(ev) => {
              setDial(`${dial}` + "#");
            }}
          >
            #
          </a>
        </div>

        <div className="call-row">
          <a
            class="button is-large"
            style={{
              backgroundColor: "transparent",
              border: "none",
            }}
          ></a>
          {etablishing ? (
            <a
              class="button is-large"
              onClick={() => {
                if (invitation) {
                  /* invitation.bye(); */
                  invitation.cancel();
                  setIsCallIn(false);
                  setInvitation(null);
                }
              }}
              style={{ backgroundColor: "red", marginTop: "10px" }}
            >
              <i class="bi bi-telephone-fill" style={{ color: "white" }}></i>
            </a>
          ) : (
            <a
              class="button is-large"
              onClick={() => {
                call(dial);
              }}
              style={{ backgroundColor: "#6cc070", marginTop: "10px" }}
            >
              <i class="bi bi-telephone-fill" style={{ color: "white" }}></i>
            </a>
          )}
          <a
            class="button is-large"
            style={{
              backgroundColor: "transparent",
              border: "none",
            }}
          ></a>
        </div>
        <div className="call-row">
          <button
            className="btn btn-sm"
            data-bs-toggle="offcanvas"
            data-bs-target="#contacte"
            style={{
              color: "white",
              backgroundColor: "#6cc070",
              marginTop: "20px",
              padding: "5px 60px",
            }}
            onClick={(ev) => setItemContact(1)}
          >
            Contacts
          </button>
        </div>
      </div>
    </>
  );
};

export default Appel;
