import React from "react";
import { MoonLoader } from "react-spinners";
const LoaderFixed = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        zIndex: "9999999",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 246, 246, 0.788)",
      }}
    >
      <MoonLoader color="#61CE70" size={50}/>
    </div>
  );
};

export default LoaderFixed;
