import React from "react";
import { useNavigate } from "react-router-dom";
const SessionExpired = () => {
    const navigate = useNavigate()
  return (
    <>
    <div className="container" style={{padding:'50px 50px',display:'flex',flexDirection:'column',alignItems:'center'}}>
    <div class="alert alert-info" style={{width:'50%',textAlign:'center'}}>
        <strong>Oups !</strong> Votre session a expiré.
      </div>
      <button className="btn" onClick={(ev)=>{
        ev.preventDefault()
        ev.stopPropagation()
        navigate('/')
      }} style={{backgroundColor:'#61CE70',color:'white'}}>Se connecter</button>
    </div>
    </>
  );
};

export default SessionExpired;
