import React from "react";

const AppelEnCours = ({setDtmf,setIsCallIn,setInvitation,invitation,}) => {
  return (
    <>
      <div id="caller-digits" class="is-unselectable box has-text-centered" style={{display:'block',maxHeight:'550px',backgroundColor:'#334155'}}>
        <div>
          <canvas
            id="phone-waveform"
            style={{
              width: "250px",
              height: "40px",
              backgroundColor: "#56585a",
            }}
          ></canvas>
        </div>
        <div
          style={{
            position: "relative",
            top: "-40px",
            marginBottom: "-40px",
            float: "left",
            width: "100%",
          }}
        >
          <span id="phoneStatus" class="tag">
            Appel en cours...
          </span>{" "}
        </div>

        <div class="call-row">
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDtmf(1);
            }}
          >
            1
          </a>
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDtmf(2);
            }}
          >
            2
          </a>
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDtmf(3);
            }}
          >
            3
          </a>
        </div>

        <div class="call-row">
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDtmf(4);
            }}
          >
            4
          </a>
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDtmf(5);
            }}
          >
            5
          </a>
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDtmf(6);
            }}
          >
            6
          </a>
        </div>

        <div class="call-row">
          <a
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDtmf(7);
            }}
          >
            7
          </a>
          <span
            class="button is-large"
            onClick={(ev) => {
              ev.preventDefault();
              setDtmf(8);
            }}
          >
            8
          </span>
          <a
            class="button is-large"
            onClick={(ev) => {
              setDtmf(9);
            }}
          >
            9
          </a>
        </div>

        <div>
          <a
            class="button is-large"
            onClick={(ev) => {
              setDtmf("*");
            }}
          >
            *
          </a>
          <a
            class="button is-large"
            onClick={(ev) => {
              setDtmf(0);
            }}
          >
            0
          </a>
          <a
            class="button is-large"
            onClick={(ev) => {
              setDtmf("#");
            }}
          >
            #
          </a>
        </div>
        <div className="call-row">
          <a
            class="button is-large"
            style={{
              backgroundColor: "transparent",
              border: "none",
            }}
          ></a>
          <a
            class="button is-large"
            onClick={() => {
              if (invitation) {
                invitation.bye();
                setIsCallIn(false);
                setInvitation(null);
              }
            }}
            style={{ backgroundColor: "red" }}
          >
            <i class="fa fa-phone" style={{ color: "white"}}></i>
          </a>
          <a
            class="button is-large"
            style={{
              backgroundColor: "transparent",
              border: "none",
            }}
          ></a>
        </div>
      </div>
    </>
  );
};

export default AppelEnCours;
