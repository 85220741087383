import React, { useEffect, useState } from "react";
import { Inviter, UserAgent, Registerer } from "sip.js";
import Header from "../../components/Header";
import DefaultImageContact from "../../assets/images/user.png";
import favico from "../../assets/images/favicon.ico";
import Historique from "../historiqueAppel/Historique";
import AppelEnCours from "./AppelEnCours";
import { useToasts } from "react-toast-notifications";
import { syncHistorique, sendAudio } from "../../helpers/historique";
import { searchOptimizedData } from "../../helpers/recherche";
import {
  getNumero,
  myAccount,
  updateUser,
  getAllNumero,
} from "../../helpers/user";
import { useNavigate } from "react-router-dom";
import {
  getAllContact,
  addContact,
  updateContact,
} from "../../helpers/contact";
import Appel from "./Appel";
import "../../App.css";
import song from "../../assets/song/Xiaomi-Redmi-Note-10.mp3";
import beepAudio from "../../assets/song/beep2.mp3";
const AppelManuel = () => {
  const navigate = useNavigate();
  const infoUsers = JSON.parse(localStorage.getItem("infoUsers"));
  const isLogin = JSON.parse(localStorage.getItem("isLogin"));
  //audio à jouer
  const [audio] = useState(new Audio(song));
  const [beep] = useState(new Audio(beepAudio))
  const { addToast } = useToasts();
  const mettreAJourLargeurEcran = () => {
    if (window.innerWidth > 842) {
      const cible = document.getElementById("caller-digits");
      cible.style = "display:block;background-color:#54595F;max-height:550px";
      const cible2 = document.querySelector(".tabHistorique");
      cible2.style = "display:block";
    }
    if (window.innerWidth < 843) {
      const cible = document.getElementById("caller-digits");
      cible.style = "display:none";
      const cible2 = document.querySelector(".tabHistorique");
      cible2.style = "display:block";
    }
  };
  const [listCampagne, setListCampagne] = useState(
    infoUsers?.data?.user?.campagnes ? infoUsers?.data?.user?.campagnes : []
  );
  let audioInterne;
  let audioExterne;
  const [mediaRecRemote, setMediaRecRemote] = useState();
  const [audioChunksLocal, setAudioChunksLocal] = useState([]);
  const [campagneActuelle, setCampagneActuelle] = useState();
  const [allNumero, setAllNumero] = useState([]);
  const [allNumWithCampagne, setAllNumWithCampagne] = useState();
  const [filtreNum, setFiltreNum] = useState("tous");
  const [numToUse, setNumToUse] = useState();
  const [numActuel, setNumActuel] = useState("---");
  const [itemContact, setItemContact] = useState(1);
  const [contact, setContact] = useState([]);
  const [originalContact, setOriginalContact] = useState([]);
  const [invitation, setInvitation] = useState();
  const [isCallIn, setIsCallIn] = useState(false);
  const [dial, setDial] = useState("");
  const [uA, setUa] = useState();
  const [dtmf, setDtmf] = useState(null);
  const [appelEncours, setAppelEnCours] = useState(false);
  const [etablishing, setEtablishing] = useState(false);
  const [infoCaller, setInfoCaller] = useState();
  const [bool, setBool] = useState(false);
  const [boolNum, setBoolNum] = useState(false);
  const [nameCt, setNameCt] = useState("");
  const [telCt, setTelCt] = useState("");
  const [adresseCt, setAdresseCt] = useState("");
  const [idContact, setIdContact] = useState("");
  const [entrepriseCt, setEntrepriseCt] = useState("");
  const [posteCt, setPosteCt] = useState("");
  const [boolCt, setBoolCt] = useState(false);
  const [a, setA] = useState(false);
  const showNotification = () => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          new Notification("Appel entrante", {
            body: "Vous avez reçu un appel entrant sur VICITELECOM. Veuillez vous rendre sur la page",
            icon: favico,
          });
        }
      });
    }
  };

  //enregistrement et play du remote media
  const setupRemoteMedia = (session) => {
    const mediaElement = document.getElementById("remoteAudio");
    const remoteStream = new MediaStream();
    const mediaRecorder = new MediaRecorder(remoteStream);
    setMediaRecRemote(mediaRecorder);
    let recordedChunks = [];
    session.sessionDescriptionHandler.peerConnection
      .getReceivers()
      .forEach((receiver) => {
        if (receiver.track) {
          remoteStream.addTrack(receiver.track);
        }
      });

    mediaElement.srcObject = remoteStream;
    mediaElement.play();
    // Enregistrement du flux distant
    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        recordedChunks.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      const recordedBlob = new Blob(recordedChunks, { type: "audio/wav" });
      audioExterne = recordedBlob;
      //const audioUrl = URL.createObjectURL(recordedBlob);

      // Créer une balise audio pour rejouer le fichier enregistré
      /*  const playbackElement = document.createElement("audio");
      playbackElement.style =
        "position:fixed; z-index:99999999999;top:0;right:0";
      playbackElement.controls = true;
      playbackElement.src = audioUrl;
      document.body.appendChild(playbackElement);
      const downloadLink = document.createElement("a");
      downloadLink.style = "display:block; margin-top:10px;positon:fixed;top:0;left:0;z-index:10000000000000000000000000000000"; // Ajuster le style selon vos besoins
      downloadLink.innerHTML = "Télécharger le fichier audio";
      downloadLink.href = audioUrl;
      downloadLink.download = "enregistrement_audio.wav";
      document.body.appendChild(downloadLink); */
    };

    // Commencer l'enregistrement
    mediaRecorder.start();
  };

  // Appeler cleanupLocalMedia lors de la fin de la session
  const cleanupMedia = () => {
    const mediaElement = document.getElementById("remoteAudio");
    mediaElement.srcObject = null;
    mediaElement.pause();
  };

  //variable du start recording
  let mediaRecorder;

  // Fonction pour commencer l'enregistrement audio

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorder = new MediaRecorder(stream);
        // Événement déclenché lorsque des données audio sont disponibles
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            setAudioChunksLocal(audioChunksLocal.push(event.data));
            //audioChunks.push(event.data);
          }
        };

        // Événement déclenché lorsque l'enregistrement est terminé
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunksLocal, { type: "audio/wav" });
          audioInterne = audioBlob;
          /*  const audioUrl = URL.createObjectURL(audioBlob);
          // Utilisez audioUrl comme source pour une balise audio ou envoyez-le à un serveur
          console.log(
            "Enregistrement terminé. URL du fichier audio:",
            audioUrl
          );
          const playbackElement = document.createElement("audio");
          playbackElement.style =
            "position:fixed; z-index:99999999999;top:100px;right:0";
          playbackElement.controls = true;
          playbackElement.src = audioUrl;
          document.body.appendChild(playbackElement);
          const downloadLink = document.createElement("a");
          downloadLink.style =
            "display:block; margin-top:10px;positon:fixed;top:100px;left:0;z-index:9999999999999999999999999999999999"; // Ajuster le style selon vos besoins
          downloadLink.innerHTML = "Télécharger le fichier audio";
          downloadLink.href = audioUrl;
          downloadLink.download = "enregistrement_audio_interne.wav";
          document.body.appendChild(downloadLink);
          // Réinitialiser les chunks pour l'enregistrement suivant
          // audioChunks = [];
          setAudioChunksLocal([]); */
        };

        // Commencer l'enregistrement
        mediaRecorder.start();
        console.log("Enregistrement audio en cours...");
      })
      .catch((error) => {
        console.error("Erreur lors de l'accès au microphone:", error);
      });
  };

  // Fonction pour arrêter l'enregistrement audio
  const stopRecording = async () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      await mediaRecorder.stop();
      console.log("Arrêt de l'enregistrement audio.");
    }
  };

  //gestion des session etablie, terminer , ringing , etc
  const onSessionStateChange = (inviter) => async (session) => {
    if (session == "Establishing") {
      setEtablishing(true);
    }
    if (session == "Established") {
      beep.pause()
      beep.currentTime = 0
      audio.pause();
      audio.currentTime = 0;
      startRecording();
      setupRemoteMedia(inviter);
      if (window.innerWidth < 843) {
        const cible = document.getElementById("caller-digits");
        cible.style = "display:block;background-color:#54595F;max-height:550px";
      }
      setEtablishing(false);
      setAppelEnCours(true);
      updateUser({ statut: 2 }, infoUsers?.data?.user?._id, infoUsers?.token)
        .then((res) => {
          console.log("status OCCUPE");
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (session == "Terminated") {
      stopRecording();
      beep.pause()
      beep.currentTime = 0
      audio.pause();
      audio.currentTime = 0;
      setEtablishing(false);
      setAppelEnCours(false);
      setIsCallIn(false);
      setInvitation(null);
      cleanupMedia();
      setTimeout(() => {}, 2000);
      await myAccount(infoUsers?.token, infoUsers?.data?.user?._id)
        .then((res) => {
          if (res?.data?.data) {
            console.log(res.data.data.reference);
            console.log("------------dst---------------");
            console.log(audioExterne);
            console.log("------------src----------------");
            console.log(audioInterne);

            const formData = new FormData();
            formData.append("internalAudio", audioInterne);
            formData.append("externalAudio", audioExterne);
            formData.append(
              "reference",
              res.data?.data?.reference ? res.data.data.reference : null
            );
            //ne pas oublier de mettre les state à null pour les variable à encore utiliser
            sendAudio(formData, infoUsers?.token)
              .then((res) => {
                console.log(res);

                audioExterne = null;
                audioInterne = null;
              })
              .catch((err) => {
                console.log(err);
                audioExterne = null;
                audioInterne = null;
              });
          } else {
            audioExterne = null;
            audioInterne = null;
          }
        })
        .catch((err) => {
          audioExterne = null;
          audioInterne = null;
        });
      await updateUser(
        { statut: 1, reference: null },
        infoUsers?.data?.user?._id,
        infoUsers?.token
      )
        .then((res) => {
          console.log("status dispo");
        })
        .catch((err) => {
          console.log(err);
        });
      setA(!a);
    }
  };

  const call = (num) => {
    if (uA && num) {
      const target = UserAgent.makeURI(
        `sip:${num}@${process.env.REACT_APP_DOMAIN}`
      );

      const inviter = new Inviter(uA, target);
      setInvitation(inviter);
      inviter.stateChange.addListener(onSessionStateChange(inviter));
      setDial("");
      inviter.invite();
      beep.play()
    } else {
      addToast(`Merci d'indiquer un numéro valide.`, {
        appearance: "error",
        autoDismiss: true,
      });
      setDial("");
    }
  };

  const sendDTMF = (dt) => {
    const options = {
      requestOptions: {
        body: {
          contentDisposition: "render",
          contentType: "application/dtmf-relay",
          content: `Signal=${dt}`,
        },
      },
    };
    if (invitation) {
      invitation
        .info(options)
        .then(() => {
          console.log(`DTMF envoyé : ${dt}`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //fonction de registration sip
  const registration = async (num, pass, bool) => {
    const transportOptions = {
      server: `wss://${process.env.REACT_APP_DOMAIN}:8089/ws`,
    };
    const uri = UserAgent.makeURI(`sip:${num}@${process.env.REACT_APP_DOMAIN}`);
    const onInvite = (invit) => {
      setIsCallIn(true);
      audio.play();
      showNotification();
      setInvitation(invit);
      const session = invit;
      session.stateChange.addListener(onSessionStateChange(invit));
      setInfoCaller(session.request.from);
    };
    const userAgentOptions = {
      authorizationPassword: pass,
      authorizationUsername: num,
      delegate: {
        onInvite,
      },
      transportOptions,
      uri,
      sessionDescriptionHandlerFactoryOptions: {
        constraints: {
          audio: true, // Activer l'audio
          video: false, // Désactiver la vidéo
        },
      },
      /* media: {
        remote: {
          audio: remoteAudioSource, // Utilisation de la source audio
        },
      }, */
    };

    const userAgent = new UserAgent(userAgentOptions);
    userAgent
      .start()
      .then(() => {
        const register = new Registerer(userAgent);
        register
          .register()
          .then(() => {})
          .catch((err) => {
            addToast(
              `Erreur lors de l'enregistrement SIP, veuillez contacter l'administrateur.`,
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          });
        if (bool) {
          setUa(userAgent);
        }
      })
      .catch((err) => {
        console.log(err);
        addToast(
          `Votre numéro est invalide ou à expirer, veuillez contacter l'administrateur.`,
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      });
  };

  //recuperation info user
  useEffect(() => {
    if (!isLogin) {
      navigate("/Expired_session");
      /* return <SessionExpired />; */
    }
    myAccount(infoUsers?.token, infoUsers?.data?.user?._id)
      .then((res) => {
        if (res?.data?.data) {
          setListCampagne(
            res?.data?.data?.campagnes ? res.data.data.campagnes : []
          );
          if (res?.data?.data?.campagneActuelle?._id) {
            setCampagneActuelle(res.data.data.campagneActuelle);
          } else {
            setCampagneActuelle(null);
          }
          if (res?.data?.data?.numeroActuel) {
            setNumActuel(res.data.data.numeroActuel);
          } else {
            setNumActuel("---");
          }
          if (infoUsers?.data?.user) {
            const ext = infoUsers.data.user.extension;
            const pass = infoUsers.data.user.pass;
            registration(ext, pass, true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [boolNum]);
  //recuperation numero
  useEffect(() => {
    //registration("1500", "1234");
    if (!isLogin) {
      navigate("/Expired_session");
      /* return <SessionExpired />; */
    }
    getAllNumero(infoUsers?.token)
      .then((res) => {
        if (res.data.data) {
          setAllNumWithCampagne(res.data.data);
          const allListNum = res.data.data;
          let allNum = [];

          allListNum.forEach((item) => {
            if (item.numeros && Array.isArray(item.numeros)) {
              allNum = allNum.concat(item.numeros);
            }
          });
          setAllNumero(allNum);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Envoye DTMF
  useEffect(() => {
    sendDTMF(dtmf);
  }, [dtmf]);

  //Gestion responsivite
  useEffect(() => {
    if (window.innerWidth < 843) {
      const cible = document.getElementById("caller-digits");
      cible.style = "display:none";
      const cible2 = document.querySelector(".tabHistorique");
      cible2.style = "display:block";
    }
    window.addEventListener("resize", mettreAJourLargeurEcran);

    // Retirer l'écouteur d'événement lorsque le composant est démonté
    return () => {
      window.removeEventListener("resize", mettreAJourLargeurEcran);
    };
  }, []);

  //Gestion recuperation contact
  useEffect(() => {
    if (!isLogin) {
      navigate("/Expired_session");
      /* return <SessionExpired />; */
    }
    getAllContact(infoUsers?.token, infoUsers?.data?.user?._id)
      .then((res) => {
        if (res.data.data) {
          setContact(res.data.data);
          setOriginalContact(res.data.data);
        } else {
          addToast(`Une erreur s'est produite.`, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (isLogin) {
          addToast(
            `Une erreur s'est produit durant la récupération des contacts.`,
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      });
  }, [boolCt]);
  useEffect(() => {
    if (!false) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          // Autorisation accordée, vous pouvez maintenant utiliser le flux audio
          console.log("Permission granted");
          // ... code pour configurer et utiliser le flux audio (par exemple, avec SIP.js)
        })
        .catch((error) => {
          console.error("Error accessing microphone:", error);
        })
        .finally(() => {});
    }
  }, []);
  return (
    <>

      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        <Header active={"tel"} uA={uA} />
        <div class="app">
          <div
            style={{
              width: "100%",
              height: "80%",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              padding: "20px 0px",
            }}
          >
            <audio
              controls
              id="remoteAudio"
              style={{ display: "none" }}
            ></audio>
            <audio controls id="localAudio" style={{ display: "none" }}></audio>
            {appelEncours ? (
              <>
                <AppelEnCours
                  setDtmf={setDtmf}
                  setIsCallIn={setIsCallIn}
                  setInvitation={setInvitation}
                  invitation={invitation}
                />
              </>
            ) : (
              <>
                <Appel
                  allExtension={allNumWithCampagne}
                  extensionToUse={numToUse}
                  setExtensionToUse={setNumToUse}
                  setDial={setDial}
                  dial={dial}
                  etablishing={etablishing}
                  invitation={invitation}
                  setIsCallIn={setIsCallIn}
                  setInvitation={setInvitation}
                  call={call}
                  setItemContact={setItemContact}
                  numeroActuel={numActuel}
                  updateUser={updateUser}
                  bool={boolNum}
                  setBool={setBoolNum}
                  listCampagne={listCampagne}
                  filtreNum={filtreNum}
                  setFiltreNum={setFiltreNum}
                />
              </>
            )}
            <Historique
              a={a}
              bool={bool}
              setDial={setDial}
              setBool={setBool}
              allExtension={[
                ...allNumero,
                {
                  numero: infoUsers?.data?.user?.extension
                    ? infoUsers?.data?.user?.extension
                    : "",
                },
              ]}
              listCampagne={listCampagne}
              campagneActuelle={campagneActuelle}
              allContact={originalContact}
            />
          </div>
        </div>
        {isCallIn && (
          <>
            <div
              style={{
                position: "fixed",
                zIndex: "9999999",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1 style={{ color: "white" }}>
                Appel entrant{" "}
                {infoCaller
                  ? ` de ${
                      infoCaller.displayName
                        ? infoCaller.displayName
                        : "Inconnu"
                    } (${
                      infoCaller.uri?.user ? infoCaller.uri.user : "Inconnu"
                    })`
                  : "Inconnu"}
              </h1>
              <div
                className="mt-4"
                style={{
                  width: "400px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <button
                  className="btn btn-success"
                  onClick={(ev) => {
                    ev.preventDefault();
                    if (invitation) {
                      invitation.accept();
                      setIsCallIn(false);
                    }
                  }}
                >
                  Décrocher
                </button>
                <button
                  className="btn btn-danger mt-5"
                  onClick={(ev) => {
                    ev.preventDefault();
                    if (invitation) {
                      invitation.reject();
                      setIsCallIn(false);
                      setInvitation(null);
                    }
                  }}
                >
                  Rejeter
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <div class="offcanvas offcanvas-start" id="contacte">
        <div class="offcanvas-header">
          <h1 class="offcanvas-title">Contacts</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div class="offcanvas-body" key={222}>
          {itemContact == 1 && (
            <>
              <div
                className="mb-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <button
                  className="btn btn-sm"
                  style={{ color: "white", backgroundColor: "#6cc070" }}
                  onClick={(ev) => {
                    setNameCt("");
                    setTelCt("");
                    setAdresseCt("");
                    setEntrepriseCt("");
                    setPosteCt("");
                    setItemContact(3);
                  }}
                >
                  + Ajouter
                </button>
                <div
                  className="champRecherche"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <span
                    style={{
                      padding: "8px 12px 8px 12px",
                      backgroundColor: "rgba(226, 232, 240, 0.6)",
                      borderRadius: "8px",
                      height: "40px",
                      display: "flex",
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#818CF8"
                        style={{ width: "18px", height: "18px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="rechercheTabulator"
                      placeholder="Recherche"
                      onChange={(ev) => {
                        const newData = searchOptimizedData(
                          ev.target.value,
                          contact
                        );
                        setContact(newData);
                      }}
                    />
                  </span>
                </div>
              </div>
              {contact &&
                contact.map((el) => {
                  return (
                    <>
                      <div
                        className="formContact"
                        style={{ marginBottom: "15px" }}
                      >
                        <div className="imageContact">
                          <img
                            className="photoContact"
                            src={DefaultImageContact}
                            alt=""
                          />
                        </div>
                        <div className="infoContact">
                          <span className="simpleTexte">
                            {el.name ? el.name : "---"}
                          </span>
                          <span className="numContact">
                            {el.tel ? el.tel : "---"}
                          </span>
                        </div>
                        <i
                          class="bi bi-telephone-fill"
                          style={{ color: "lightgray", cursor: "pointer" }}
                          data-bs-dismiss="offcanvas"
                          onClick={(ev) => setDial(el.tel ? el.tel : "")}
                        ></i>
                        <i
                          class="bi bi-pencil-fill"
                          style={{ color: "lightgray", cursor: "pointer" }}
                          onClick={(ev) => {
                            setNameCt(el.name);
                            setTelCt(el.tel);
                            setAdresseCt(el.adresse);
                            setEntrepriseCt(el.entreprise);
                            setPosteCt(el.poste);
                            setIdContact(el._id);
                            setItemContact(2);
                          }}
                        ></i>
                        <i
                          class="bi bi-trash3-fill"
                          style={{ color: "#EB455F", cursor: "pointer" }}
                          onClick={(ev) => {
                            updateContact(
                              { _id: el._id, archive: true },
                              infoUsers.token
                            )
                              .then((res) => {
                                console.log(res);
                                addToast("Contact archivé avec succès.", {
                                  appearance: "success",
                                  autoDismiss: true,
                                });
                                setItemContact(1);
                                setBoolCt(!boolCt);
                              })
                              .catch((err) => {
                                console.log(err);
                                if (err.response?.data?.message) {
                                  addToast(err.response?.data?.message, {
                                    appearance: "error",
                                    autoDismiss: true,
                                  });
                                } else {
                                  addToast(`Une erreur s'est produit.`, {
                                    appearance: "error",
                                    autoDismiss: true,
                                  });
                                }
                              });
                          }}
                        ></i>
                      </div>
                    </>
                  );
                })}
            </>
          )}
          {itemContact == 2 && (
            <>
              <form
                onSubmit={(ev) => {
                  ev.preventDefault();
                  let newData = {};
                  newData.name = nameCt;
                  newData.tel = telCt;
                  newData.adresse = adresseCt;
                  newData.entreprise = entrepriseCt;
                  newData.poste = posteCt;
                  newData._id = idContact;
                  updateContact(newData, infoUsers.token)
                    .then((res) => {
                      addToast("Contact mis à jour avec succès.", {
                        appearance: "success",
                        autoDismiss: true,
                      });
                      setItemContact(1);
                      setBoolCt(!boolCt);
                    })
                    .catch((err) => {
                      console.log(err);
                      if (err.response?.data?.message) {
                        addToast(err.response?.data?.message, {
                          appearance: "error",
                          autoDismiss: true,
                        });
                      } else {
                        addToast(`Une erreur s'est produite.`, {
                          appearance: "error",
                          autoDismiss: true,
                        });
                      }
                    });
                }}
              >
                <div class="mb-3">
                  <label class="form-label">Nom:</label>
                  <input
                    type="text"
                    class="form-control input-sm"
                    defaultValue={nameCt}
                    onChange={(ev) => {
                      setNameCt(ev.target.value);
                    }}
                    placeholder="Nom"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Téléphone:</label>
                  <input
                    type="text"
                    class="form-control input-sm"
                    defaultValue={telCt}
                    onChange={(ev) => {
                      setTelCt(ev.target.value);
                    }}
                    placeholder="Téléphone"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Adresse:</label>
                  <input
                    type="text"
                    class="form-control input-sm"
                    defaultValue={adresseCt}
                    onChange={(ev) => {
                      setAdresseCt(ev.target.value);
                    }}
                    placeholder="Adresse"
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Entreprise:</label>
                  <input
                    type="text"
                    class="form-control input-sm"
                    defaultValue={entrepriseCt}
                    onChange={(ev) => {
                      setEntrepriseCt(ev.target.value);
                    }}
                    placeholder="Entreprise"
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Poste:</label>
                  <input
                    type="text"
                    class="form-control input-sm"
                    defaultValue={posteCt}
                    onChange={(ev) => {
                      setPosteCt(ev.target.value);
                    }}
                    placeholder="Poste"
                  />
                </div>
                <div
                  class="mb-3"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <div>
                    <button
                      className="btn btn-sm"
                      style={{
                        color: "white",
                        backgroundColor: "#334155",
                        marginRight: "10px",
                      }}
                      onClick={(ev) => setItemContact(1)}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-sm"
                      style={{ color: "white", backgroundColor: "#6cc070" }}
                      type="submit"
                    >
                      Mettre à jour
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
          {itemContact == 3 && (
            <>
              <form
                onSubmit={(ev) => {
                  ev.preventDefault();
                  let newData = {};
                  newData.name = nameCt;
                  newData.tel = telCt;
                  newData.adresse = adresseCt;
                  newData.entreprise = entrepriseCt;
                  newData.poste = posteCt;
                  newData.clientId = infoUsers.data.user._id;
                  addContact(newData, infoUsers.token)
                    .then((res) => {
                      addToast("Contact ajouté avec succès.", {
                        appearance: "success",
                        autoDismiss: true,
                      });
                      setItemContact(1);
                      setBoolCt(!boolCt);
                    })
                    .catch((err) => {
                      if (err.response?.data?.message) {
                        addToast(err.response?.data?.message, {
                          appearance: "error",
                          autoDismiss: true,
                        });
                      } else {
                        addToast(`Une erreur s'est produite.`, {
                          appearance: "error",
                          autoDismiss: true,
                        });
                      }
                    });
                }}
              >
                <div class="mb-3">
                  <label class="form-label">Nom:</label>
                  <input
                    type="text"
                    class="form-control input-sm"
                    defaultValue={nameCt}
                    onChange={(ev) => {
                      setNameCt(ev.target.value);
                    }}
                    placeholder="Nom"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Téléphone:</label>
                  <input
                    type="text"
                    class="form-control input-sm"
                    defaultValue={telCt}
                    onChange={(ev) => {
                      setTelCt(ev.target.value);
                    }}
                    placeholder="Téléphone"
                    required
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Adresse:</label>
                  <input
                    type="text"
                    class="form-control input-sm"
                    defaultValue={adresseCt}
                    onChange={(ev) => {
                      setAdresseCt(ev.target.value);
                    }}
                    placeholder="Adresse"
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Entreprise:</label>
                  <input
                    type="text"
                    class="form-control input-sm"
                    defaultValue={entrepriseCt}
                    onChange={(ev) => {
                      setEntrepriseCt(ev.target.value);
                    }}
                    placeholder="Entreprise"
                  />
                </div>
                <div class="mb-3">
                  <label class="form-label">Poste:</label>
                  <input
                    type="text"
                    class="form-control input-sm"
                    defaultValue={posteCt}
                    onChange={(ev) => {
                      setPosteCt(ev.target.value);
                    }}
                    placeholder="Poste"
                  />
                </div>
                <div
                  class="mb-3"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <div>
                    <button
                      className="btn btn-sm"
                      style={{
                        color: "white",
                        backgroundColor: "#334155",
                        marginRight: "10px",
                      }}
                      onClick={(ev) => setItemContact(1)}
                    >
                      Annuler
                    </button>
                    <button
                      className="btn btn-sm"
                      style={{ color: "white", backgroundColor: "#6cc070" }}
                      type="submit"
                    >
                      Ajouter
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      <div className="footerNavbar">
        <span
          onClick={(e) => {
            e.preventDefault();
            const cible = document.getElementById("caller-digits");
            const toHide = document.querySelector(".tabHistorique");
            toHide.style = "display:none";
            cible.style =
              "display:block;background-color:#54595F;max-height:550px";
          }}
        >
          <i class="bi bi-grid-3x3-gap-fill" style={{ color: "#6cc070" }}></i>
        </span>
        <span
          onClick={(e) => {
            e.preventDefault();
            const cible = document.querySelector(".tabHistorique");
            const toHide = document.getElementById("caller-digits");
            toHide.style = "display:none";
            cible.style = "display:block";
          }}
        >
          <i class="bi bi-clock-history" style={{ color: "#6cc070" }}></i>
        </span>
      </div>
    </>
  );
};

export default AppelManuel;
