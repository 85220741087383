import React from "react";
import image from "../assets/images/pageNotFound.png";
const PageNotFound = () => {
  return (
    <>
      <div
        style={{ width: "100%", height: "100vh", backgroundColor: "#C2D9FF" }}
      >
        <img
          src={image}
          alt="Page not found"
          style={{ width: "100%", height: "auto" }}
        />
      </div>
    </>
  );
};

export default PageNotFound;
