import axios from "axios";

const login = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/historyOneUser`,
      config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};
const logout = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/historyOneUser`,
      config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};
const getUser = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/client/myAccount/${id}`,
      config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};
const updatePassword = async (current, newPass, confirmPass, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_URL}/client/updateMyPassword`,
      {
        passwordCurrent: current,
        password: newPass,
        passwordConfirm: confirmPass,
      },
      config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};
const myAccount = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/utilisateurs/myAccount/${id}`,
      config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};

const getNumero = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/numeros/myNumero`,
      config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};
const getAllNumero = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_URL}/numeros/userNum`,
      config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};
const updateUser = async (donner, id, token) => {
  console.log(donner);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const data = await axios.patch(
      `${process.env.REACT_APP_URL}/utilisateurs/${id}`,
      donner,
      config
    );
    return data;
  } catch (err) {
    return {
      erreur: err,
    };
  }
};

export { login, logout, getUser, updatePassword, getNumero, myAccount,updateUser,getAllNumero };
