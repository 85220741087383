import React, { useState } from "react";
import { Form, Button, Card, InputGroup } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState();
  const navigate = useNavigate();
  const infoUsers = JSON.parse(localStorage.getItem("infoUsers"));
  console.log(infoUsers);
  const handleLogin = () => {
    axios
      .post(`${process.env.REACT_APP_URL}/users/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.data) {
          localStorage.setItem("infoUsers", JSON.stringify(res.data));
          navigate("/Appel_Manuel");
        } else {
          alert(`Une erreur s'est produite`);
        }
      })
      .catch((err) => {
        alert(`Une erreur s'est produite`);
      });
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <Card style={{ width: "18rem", backgroundColor: "#61CE70" }}>
        <Card.Body>
          <Card.Title className="text-center">LOGIN</Card.Title>
          <Form
            onSubmit={(ev) => {
              ev.preventDefault();
              handleLogin();
            }}
          >
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                placeholder="Adresse email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  variant="outline-secondary"
                  onClick={handleShowPassword}
                  style={{ backgroundColor: "#fff", border: "none" }}
                >
                  {showPassword ? (
                    <i className="fa fa-eye-slash"></i>
                  ) : (
                    <i className="fa fa-eye"></i>
                  )}
                </Button>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Se souvenir de moi" />
            </Form.Group>
            <div className="d-flex">
              <Button
                className="btn btn-primary w-100"
                type="submit"
                style={{ backgroundColor: "#343a40", border: "none" }}
              >
                Se connecter
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Home;
